import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "mercadolibre"
    }}>{`Mercadolibre`}</h2>
    <p><a parentName="p" {...{
        "href": "https://developers.mercadolibre.com.ar"
      }}>{`Developer Docs`}</a></p>
    <h3 {...{
      "id": "crear-aplicación-mercadolibre"
    }}>{`Crear Aplicación Mercadolibre`}</h3>
    <p>{`::: tip
Estas instrucciones son utiles para que el cliente registre la aplicación desde su propia cuenta de Mercadolibre.
:::`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Ir a `}<a parentName="p" {...{
            "href": "https://developers.mercadolibre.com.ar"
          }}>{`https://developers.mercadolibre.com.ar`}</a>{` Logueado como el usuario de meli que sera dueño de la app`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click en el nombre del usuario arriba a la derecha -> Mis Aplicaciones -> Crear Nueva Aplicación`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ingresar la Información Básica como prefieran y click en Confirmar`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ingresar la siguiente configuración:`}</p>
      </li>
    </ol>
    <p>{`Redirect URI: `}<a parentName="p" {...{
        "href": "https://APPDOMAIN.COM/ml-auth-redirect"
      }}>{`https://APPDOMAIN.COM/ml-auth-redirect`}</a></p>
    <p>{`Scopes: Todos `}</p>
    <p>{`Topicos: items, payments, pictures, orders_v2, shipments (Varia segun el proyecto)`}</p>
    <p>{`Notificaciones callbacks URL
`}<a parentName="p" {...{
        "href": "https://APPDOMAIN.COM/webhooks/mercadolibre"
      }}>{`https://APPDOMAIN.COM/webhooks/mercadolibre`}</a></p>
    <p>{`Click en Crear, ahora pueden volver a Mis Aplicaciones y ver el id y key de la app que deben pegarse en el .env.`}</p>
    <h3 {...{
      "id": "creando-usuarios-de-prueba"
    }}>{`Creando usuarios de prueba`}</h3>
    <p>{`::: danger
Para pruebas internas de Ecomerciar solicitar usuarios test existentes al equipo antes de crear nuevos,
ya que hay un limite de 10 usuarios de test. Estas instrucciones son principalmente para que el equipo tecnico del cliente (si lo tiene) las hagan en su cuenta de Mercadolibre.
:::`}</p>
    <p><a parentName="p" {...{
        "href": "https://developers.mercadolibre.com.ar/es_ar/realiza-pruebas#Crea-un-usuario-de-test"
      }}>{`https://developers.mercadolibre.com.ar/es_ar/realiza-pruebas#Crea-un-usuario-de-test`}</a></p>
    <pre><code parentName="pre" {...{}}>{`curl -X POST -H "Content-Type: application/json" -d '{"site_id":"MLA"}' /
    https://api.mercadolibre.com/users/test_user?access_token=...
`}</code></pre>
    <p>{`Notas:`}</p>
    <ul>
      <li parentName="ul">{`Hay un limite de 10 usuarios de prueba por cuenta y vencen a los 60 dias.`}</li>
      <li parentName="ul">{`Para crear usuarios de prueba es necesario un Access Token, este lo pueden obtener de dos maneras:
A- Access token de la plaicaion:
Ingresando a esta direccion, buscar la seccion "Obtén tu access token" e ingresar el APP_ID de la aplicacion
`}<a parentName="li" {...{
          "href": "https://developers.mercadolibre.com.ar/es_ar/autenticacion-y-autorizacion/"
        }}>{`https://developers.mercadolibre.com.ar/es_ar/autenticacion-y-autorizacion/`}</a></li>
    </ul>
    <h3 {...{
      "id": "colección-de-llamadas-a-webhooks-en-postman"
    }}>{`Colección de llamadas a webhooks en Postman`}</h3>
    <p>{`Util para probar los webhooks incluso en localhost`}</p>
    <p>{`Download: `}<a parentName="p" {...{
        "href": "https://www.getpostman.com/collections/5fd35cdf82d3e9f09d8b"
      }}>{`https://www.getpostman.com/collections/5fd35cdf82d3e9f09d8b`}</a></p>
    <h3 {...{
      "id": "mercadoenvios-flex"
    }}>{`Mercadoenvios Flex`}</h3>
    <p><a parentName="p" {...{
        "href": "https://developers.mercadolibre.com.ar/es_ar/mercado-envios-flex"
      }}>{`https://developers.mercadolibre.com.ar/es_ar/mercado-envios-flex`}</a></p>
    <h2 {...{
      "id": "mercadopago"
    }}>{`Mercadopago`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.mercadopago.com.ar/developers/"
      }}>{`Developer Docs`}</a></p>
    <h3 {...{
      "id": "datos-tarjeta-para-prueba"
    }}>{`Datos tarjeta para prueba`}</h3>
    <h4 {...{
      "id": "tarjetas-argentina"
    }}>{`Tarjetas Argentina`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Visa`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Mastercard`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`American Express`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4509 9535 6623 3704`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5031 7557 3453 0604`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3711 803032 57522`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "card-holder-names"
    }}>{`Card Holder Names`}</h4>
    <p>{`APRO: Pago aprobado.
CONT: Pago pendiente.
CALL: Rechazo llamar para autorizar.
FUND: Rechazo por monto insuficiente.
SECU: Rechazo por código de seguridad.
EXPI: Rechazo por fecha de expiración.
FORM: Rechazo por error en formulario.
OTHE: Rechazo general`}</p>
    <h3 {...{
      "id": "sponsor-id"
    }}>{`Sponsor ID`}</h3>
    <p>{`::: warning 230106269
Aplicar nuestro sponsor ID de MercadoPago en el codigo para cada cliente/integración.
:::`}</p>
    <h3 {...{
      "id": "crear-aplicación-mercadopago"
    }}>{`Crear Aplicación Mercadopago`}</h3>
    <p>{`::: tip TIPS`}</p>
    <ul>
      <li parentName="ul">{`Estas instrucciones son útiles para que el cliente registre la aplicación desde su propia cuenta de Mercadopago.`}</li>
      <li parentName="ul">{`Las rutas usadas en este ejemplo (/ml-auth-redirect y /webhooks/mercadopago) son las que usamos habitualmente en integraciones custom pero pueden variar segun el proyecto. Para integraciones Woocommerce se usa la URL de webhooks configurada dese Woocommerce.
:::`}</li>
    </ul>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Ir a `}<a parentName="p" {...{
            "href": "https://www.mercadopago.com.ar/developers/"
          }}>{`https://www.mercadopago.com.ar/developers/`}</a>{` Logueado como el usuario de meli que sera dueño de la app`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click en el nombre del usuario arriba a la derecha -> Aplicaciones -> Create New Application`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ingresar la Información Básica como prefieran`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ingresar la siguiente configuración:`}</p>
      </li>
    </ol>
    <ul>
      <li parentName="ul">{`Redirect URI: `}<a parentName="li" {...{
          "href": "https://APPDOMAIN.COM/ml-auth-redirect"
        }}>{`https://APPDOMAIN.COM/ml-auth-redirect`}</a></li>
    </ul>
    <p>{`(Solo se usa en proyectos que requeiran flujo OAuth, por ejemplo para integaciones tipo Marketplace pero el campo es requerido)`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Authorized Javascript Origin: APPDOMAIN.COM`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Scopes: Todos (o los que requiera el proyecto)`}</p>
      </li>
    </ul>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Notificaciones:`}</li>
    </ol>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`URL: `}<a parentName="p" {...{
            "href": "https://APPDOMAIN.COM/webhooks/mercadopago"
          }}>{`https://APPDOMAIN.COM/webhooks/mercadopago`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Topics:  payments (o los que requiera el proyecto)`}</p>
      </li>
    </ul>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Click en Create Application.`}</li>
    </ol>
    <p>{`Ahora pueden ver las credenciales de la aplicación desde el menu de arrba a la derecha -> Credenciales,`}</p>
    <h3 {...{
      "id": "creando-usuarios-de-prueba-1"
    }}>{`Creando usuarios de prueba`}</h3>
    <p>{`::: danger
Para pruebas internas de Ecomerciar solicitar usuarios test existentes al equipo antes de crear nuevos,
ya que hay un limite de 10 usuarios de test. Estas instrucciones son principalmente para que el equipo tecnico del cliente (si lo tiene) las hagan en su cuenta de Mercadopago.
:::`}</p>
    <p>{`Notas:`}</p>
    <ul>
      <li parentName="ul">{`Hay un limite de 10 usuarios de prueba por cuenta y vencen a los 60 dias.`}</li>
      <li parentName="ul">{`Para crear usuarios de prueba es necesario un Access Token`}</li>
    </ul>
    <h3 {...{
      "id": "colección-de-llamadas-en-postman"
    }}>{`Colección de llamadas en Postman`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      